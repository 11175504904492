<template>
  <div>
  <h1>{{name}}</h1>
    <div class="scorepanel">
      <div class="buttons" style="order: 0">
          <ScoreButton v-for="scoreInc in scoreIncrements" 
          @update="(delta) => score += delta"
          :delta="scoreInc" :key="scoreInc"/>
      </div>
      <div class="scorebox" style="order: 1">
          <h1>{{score}}</h1>
      </div>
    </div>
  </div>
</template>

<script>
import ScoreButton from './ScoreButton.vue';
export default {
    props: {
        initialData: Object,
        reversed: Boolean
    },
    data() {
        return {
            score: this.initialData.score,
            name: this.initialData.name,
            //content: this.value,
            scoreIncrements: [1, 2, 3, -1, -5],
        };
    },
    computed: {
        scorepanelflex() {
            return this.reversed ? "row-reverse" : "row";
        }
    },
    methods: {
        increment(v) {
            this.score += v;
        }
    },
    components: { ScoreButton },
    watch: {
        // whenever question changes, this function will run
        initialData: {
            handler: function(newData, ) {
                this.score = newData.score
                this.name = newData.name
            },
            deep: true
        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.buttons {
    display: flex;
    flex-flow: column nowrap;
    margin-left: 20px;
    margin-right: 20px;
}
.scorebox {
    /*height: 90%;*/
    border: 0px solid;
    flex: 20%;
    font-size: 5rem;
    margin: auto;
}
.scorepanel {
  border: 8px;
  display: flex;
  flex-flow: v-bind('scorepanelflex') nowrap;
  justify-content: flex-start;
}
</style>