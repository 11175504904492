<template>
  <div class="scoreMatch">
      <header class="scoreHeader">
        <img alt="USSSA logo" src="../assets/USSSA_shield_white_border_10_dpi.png"/>
      </header>
      <div class="scoreMain">
          <ScorePlayer class="scorePanelRed" :initialData="playerData.red" />
          <ScorePlayer class="scorePanelBlue" :initialData="playerData.blue" reversed />
      </div>
      <footer class="footer">
        <span><label>Red Player Name: </label><input v-model="playerData.red.name"/></span>
        <button @click.prevent="resetMatch">reset</button>
        <span><label>Blue Player Name: </label><input v-model="playerData.blue.name" /></span>
      </footer>
  </div>
</template>

<script>
import ScorePlayer from './ScorePlayer.vue';
export default {
    name: "HelloWorld",
    props: {
        msg: String
    },
    data() {
      var initialPlayerData = {
            score: 0,
            name: ""
      }
      return {
        playerData: {
          "red": {...initialPlayerData},
          "blue": {...initialPlayerData},
        },
        initialPlayerData: initialPlayerData
      }
    },
    components: { ScorePlayer },
    methods: {
      resetMatch() {
        this.playerData.red = {...this.initialPlayerData}
        this.playerData.blue = {...this.initialPlayerData}
      }

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.scoreMatch {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  min-height: 50vh;
  max-width: 100vw;
}

.scoreMatch > * {
  padding: 0px;
}


.scoreMain {
  display: flex;
  flex-flow: row wrap;
  flex-shrink: 1;
}

.scorePanelRed {
  background-color: lightcoral;
  /*border-top-left-radius: 0.4rem;*/
  /*border-bottom-left-radius: 0.4rem;*/
  flex: 50%;
  /*flex-flow: row nowrap;*/
  /*justify-content: flex-start;*/
}

.scorePanelBlue {
  color: white;
  background-color: dodgerblue;
  /*border-top-right-radius: 0.4rem;*/
  /*border-bottom-right-radius: 0.4rem;*/
  flex: 50%;
  /*flex-flow: row-reverse nowrap;*/
  /*justify-content: flex-start;*/
}

.scoreHeader {
  background-color: #4f4f4f;
  padding: 10px;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
}

.footer {
  color: white;
  background-color: darkblue;
  padding: 20px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

div.footer button {
  background-color: lightgrey;
  border: 0px solid;
  outline: none;
  padding: 10px;

}

div.footer button:active {
  background-color: darkgray;
  border: 0px solid;
  outline: none;
  padding: 10px;
}
</style>
