<template>
    <button @click.prevent="handleClick" :class="buttonClass">{{sign}}{{deltaAbs}}</button>
</template>

<script>

export default {
  emits: ['update'],
  props: {
    delta: Number
  },
  computed: {
    deltaAbs() {
        return Math.abs(this.delta)
    },
    sign() {
        return this.delta >=0 ? "+" : "-"
    },
    buttonClass() {
        return this.delta >=0 ? "postive" : "negative"
    }

  },
  methods: {
    handleClick() {
        this.$emit("update", this.delta)
    }
  }
}

</script>

<style scoped>
button {
    /*border-radius: 0.1rem;*/
    border-radius: 50%;
    padding: 2rem;
    background-color: transparent;
    border: 4px double white;
    /*flex: calc(20% - 10px);*/
    flex-shrink: 1;
    column-gap: 50px;
    font-size: 2rem;
    color: #ffffff;
    outline: none;
    margin: 10px;
}

button:hover {
  cursor: pointer;
}

button:active {
  background-color: white;
  /*box-shadow: 0 2px #666;*/
  border-radius: 50%;
  cursor: pointer;
  color: #000000;
}

.positive {
    background-color: #000000;
    color: #FFFF00;
    border: 0.1em double white;
}

.negative {
    border: 0.1em dotted yellow;
}
.negative:active {
  background-color: #ffff00;
}


</style>