<template>
  <ScoreMatch/>
</template>

<script>
import ScoreMatch from './components/ScoreMatch.vue'

export default {
  name: 'App',
  components: {
    ScoreMatch: ScoreMatch
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*margin-top: 60px;*/
  background-color: light-grey;
}
</style>
